import React from 'react';

import Layout from '../components/layout';
import { Row, Col, Heading, Frame, Project } from 'arwes';

import Loading from '../components/loading';

class Misc extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        });
    }
    
    render() {
        if (this.state.loading) {
            return (<Loading />);
        }
        
        return (<Layout>
            <div style={{ maxWidth: "400px", paddingBottom: "40px" }}>
                <Frame animate layer='primary' corners={4}>
                    {anim => (
                        <div style={{paddingTop: "10px"}}>
                            <Heading animate show={anim.entered} node='h1'>Misc</Heading>
                        </div>
                    )}
                </Frame>
            </div>

            <div style={{ padding: "20px" }}>
                <Row>
                    <Col s={12}>
                        <Project animate header="Lecture on Image Classification Basics">
                            <iframe title="ml-vid" width="889" height="500" src="https://www.youtube.com/embed/PMsY5PlDVQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Project> 
                    </Col>
                </Row>
            </div>
        </Layout>);
    }
}

export default Misc;